.hover-shadow-3d {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0%);
    transition: background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, transform 0.1s ease;
    &:hover {
        box-shadow: 0.5rem 0.5rem 0 rgba(233, 236, 239, 50%);
        transform: translate3d(-4px, -4px, 0) rotateZ(-1deg);
    }
}

    .internal-pages-section {
        padding-top: 110.375px !important;
    .divider {
        svg {
            height: 6vw !important;
        }
    }
}

    .internal-pages-body {
        img.body-img-rounded {
            border-radius: 0.625rem !important;
        }
    }

    .card.card-sm {
        @include media-breakpoint-down(sm) {
            margin-top: 1rem !important;
        }
    }

    .container.learn-more-container {
        max-width: 100% !important;
        @include media-breakpoint-up(sm) {
            max-width: 760px !important;
        }
        @include media-breakpoint-up(md) {
            max-width: 960px !important;
        }
    }

    .btn.back-to-top {
        position: fixed;
        bottom: 1.5rem;
        right: 1.5rem;
        z-index: 1020;
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #212529;
        scroll-behavior: smooth !important;
        transition: 0.3s ease-in-out;
    }

    .card.card-sm {
        ul {
            color: #495057 !important;
        }
        p {
            color: #495057 !important;
        }
    }