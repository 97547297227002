    .navbar {
        min-height: 110.375px; 
        margin-bottom: -110.375px;
        position: fixed;
        border-bottom: $border-width solid rgba($light,.08);
        width: 100%;
        z-index: 999;
        transition: background-color 0.2s ease !important;
    }

a.nav-link {
    font-weight: 600;
    &.dropdown-toggle::after {
        display: none !important;
    }
    &.current-page {
            color: rgba(255, 255, 255, 1) !important;
        }
    }

.btn.btn-light.get-in-touch-btn { 
    font-weight: 600;
    &:hover {
        color: $primary !important;
    }
}
  
.navbar-toggler{
    border: none;
    position: relative;
    i {
        width: 1rem;
        color: $light;
  }
}