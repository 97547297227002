a.learn-more-btn {
    padding: 0.875rem 2.25rem;
    font-size: 18.75px !important;
    line-height: 1.5;
    border-radius: 0.3125rem;
    font-family: $font-family !important;
}

@media (min-width: 768px) {
    .card-icon-2 {
        min-height: 22rem;
    }
}

.icon-round {
    flex-shrink: 0;
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 130, 0.1) !important;
    > .icon {
        height: 2.25rem;
    }
    &.bg-primary {
        background: rgba(90, 210, 255, 0.1) !important;
    }
}

.card {
    &.lte-img {
        &:hover {
            background-color: #00004f !important;
            color: $light !important;
        }
    }
    &.iot-img {
        background-color: #000 !important;  
        &:hover {
            background-color: #000 !important;
            h5 {
                color: $light !important;
            }
        }
    }
    &.cloud-services-img {
        &:hover {
            background-color: #27c4ff !important;
            color: $secondary !important;
        }
    }
}

@media(max-width: 450px) {
    .cs-column {
        margin-top: 1rem;
    }
}

    section.default-footer {
        display: flex;
        justify-content: center;
        padding: 0 !important;
    .home-copyright {
        p {
            color: #495057 !important;
            font-size: 15px;
        }
    }
}