.divider.divider-vertical {
    position: relative;
    height: 100vh;
    width: 6rem;
    top: 0;
    left: 1px;
    svg {
        height: 100vh;
        width: 6rem;
    }
}

.contact-img {
    img {
        position: absolute;
        object-fit: cover;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }
}