/* Set Bootstrap Grid Breakpoints. */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);


/* Bootstrap Import */ 
@import "bootstrap/bootstrap";
@import "bootstrap/custom_partials/_internal-pages.scss";
@import "bootstrap/custom_partials/_contact.scss";
/* Global Variables */

// Fonts 
$font-family: "Inter";

// Theme Color Scheme 

$primary:       #000082;
$primary-2:     #5ad2ff; 
$secondary:     #212529;
$light:         #FFFFFF;
$danger:        #ed3f54; 
// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
   "primary":    $primary,
   "primary-2": $primary-2,
   "secondary":  $secondary,
   "light": $light,
   "danger": $danger
   );

$theme-colors: (
    "primary":    $primary,
    "primary-2": $primary-2,
    "secondary":  $secondary,
    "light": $light,
    "danger": $danger
);

// background classes

.bg-primary{
    background-color: $primary !important; 
}

.bg-primary-2{
    background-color: $primary-2 !important; 
}

.bg-secondary{
    background-color: $secondary !important; 
}

.bg-light{
    background-color: $light !important; 
}

// text-color classes

.text-primary{
    color: $primary !important; 
}

.text-primary-2{
    color: $primary-2 !important; 
}

.text-secondary{
    color: $secondary !important; 
}

a.text-light,
.text-light{
    color: $light !important; 
}

a.btn-primary,
button.btn-primary {
    background-color: $primary !important;
    border-color: $primary !important;
    &:hover {
        color: $light !important;
        background-color: #00005c !important;
        border-color: #00004f !important;
    }
}

.btn-primary-2 {
    background-color: $primary-2 !important;
    color: $secondary !important;
    font-weight: 600 !important;
}

/* Custom Partial Imports */

@import "bootstrap/custom_partials/home";
@import "bootstrap/custom_partials/footer";
@import "bootstrap/custom_partials/nav";

/* Base Styles */

html, body {
    color: #000;
    background-color: #FFF;
    max-width: 100%; 
    overflow-x: hidden;
}

html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased; 

}

.container {
    padding-right: 12px;
    padding-left: 12px;
    margin-right: auto;
    margin-left: auto;
    &:not(.nav-container){
    @media (min-width: 992px){
        max-width: 960px !important;
    }
    @media (min-width: 768px) {
        max-width: 720px;
    }
}
    @media(max-width: 576px) {
        max-width: 100%;
    }
    &.nav-container {
        max-width: 90% !important;
    }
}

section {
    padding: 6rem 0 !important;
}

.row {
    margin-left: 0px !important; 
    margin-right: 0px !important; 
}

h1 {
    font-family: $font-family;
    font-weight: 600 !important;
}

h2 {
    font-family: $font-family;
}

h3 {
    font-family: $font-family;
}

p {
    font-family: $font-family;
    color: #495057;
}

a {
    cursor: pointer;
    text-decoration: none; 
    font-family: $font-family !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    transition: color 0.2s linear;
    &:hover {
        text-decoration: none !important;
    }
}

a.text-light {
    font-family: $font-family !important;
    font-size: 19.25px !important;
    &:hover {
        color: $light !important;
    }
}

.card {
    border-radius: 0.5rem !important;
    border: none !important;
}

// Universal Styles Specific to Your Theme Go Here

.btn-light {
    color: $primary !important;
    font-weight: 600;
}

.display-4 {
    font-weight: 600 !important;
    font-size: 3.25rem !important;
}

.lead {
    font-size: 1.2rem !important;
    font-weight: 400 !important;
    @include media-breakpoint-down(xs) {
        font-size: 1rem !important;
    }
}

.fw-600 {
    font-weight: 600 !important;
}

.opacity-50 {
    opacity: 0.5 !important;
    transition: opacity 0.2s linear;
    &:not(.copyright):hover {
        opacity: 1 !important;
    }
}

.opacity-30 {
    opacity: 0.3 !important;
}

.opacity-10 {
    opacity: 0.1 !important;
}

.layer-2 {
    z-index: 2 !important;
}

.my-md-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
}

.bg-black {
    background-color: #000;
}
